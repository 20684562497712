div.icon {
  margin-right: 8px;
}

div.root {
  border: 1px solid var(--mantine-color-blue-7);
}

.wrapper {
  align-items: center;
}
