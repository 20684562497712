.title {
  /* font family not used here to be available for text */
}

.xLarge {
  font-family: var(--mantine-font-family-headings, Poppins, sans-serif);
  font-size: 48px;
  font-weight: 600;

  letter-spacing: -0.96px; /* 48 * 0.02 or 2% */
  letter-spacing: -2%;
  line-height: 56px;
}

.large {
  font-family: var(--mantine-font-family-headings, Poppins, sans-serif);
  font-size: 36px;
  font-weight: 600;

  letter-spacing: -0.72px; /* 36 * 0.02 or 2% */
  letter-spacing: -2%;
  line-height: 44px;
}

.medium {
  font-family: var(--mantine-font-family-headings, Poppins, sans-serif);
  font-size: 28px;
  font-weight: 600;

  letter-spacing: -0.56px; /* 28 * 0.02 or 2% */
  letter-spacing: -2%;
  line-height: 32px;
}

.small {
  font-family: var(--mantine-font-family-headings, Poppins, sans-serif);
  font-size: 24px;
  font-weight: 600;

  letter-spacing: -0.48px; /* 24 * 0.02 or 2% */
  letter-spacing: -2%;
  line-height: 28px;
}

.xSmall {
  font-family: var(--mantine-font-family-headings, Poppins, sans-serif);
  font-size: 20px;
  font-weight: 600;

  letter-spacing: -0.4px; /* 24 * 0.02 or 2% */
  letter-spacing: -2%;
  line-height: 24px;
}
