.text {
  /* not empty */
}

.inter {
  font-family: var(--mantine-font-family, Inter, sans-serif);
}

.poppins {
  font-family: var(--mantine-font-family-headings, Poppins, sans-serif);
}

.headline {
  font-size: 16px;
  font-weight: 700;

  line-height: 24px;
}

.subhead {
  font-size: 14px;
  font-weight: 500;

  line-height: 20px;
}

.button {
  font-size: 15px;
  font-weight: 700;

  line-height: 20px;
}

.bodyLg {
  font-size: 16px;
  font-weight: 400;

  line-height: 24px;
}

.bodyMd {
  font-size: 14px;
  font-weight: 400;

  line-height: 20px;
}

.bodySm {
  font-size: 12px;
  font-weight: 400;

  line-height: 16px;
}

.bodyLgStrong {
  font-size: 16px;
  font-weight: 600;

  line-height: 24px;
}

.bodyMdStrong {
  font-size: 14px;
  font-weight: 600;

  line-height: 20px;
}

.bodySmStrong {
  font-size: 12px;
  font-weight: 600;

  line-height: 16px;
}

.fieldLabel {
  font-size: 12px;
  font-weight: 500;

  line-height: 16px;
  text-transform: capitalize;
}

.caption {
  font-size: 12px;
  font-weight: 400;

  line-height: 16px;
}

.tableHeader {
  font-size: 13px;
  font-weight: 500;

  line-height: 16px;
  text-transform: capitalize;
}

.smallLabel {
  font-size: 12px;
  font-weight: 600;

  line-height: 16px;
}
